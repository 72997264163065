import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import login from '@/views/login.vue'
import blank from '@/views/blank.vue'
import ios from '@/views/ios.vue'
import home from '@/views/home/index.vue'
import editor from '@/views/editor/index.vue'
import about from '@/views/about.vue'
import article from '@/views/article/index.vue'
import chatGame from '@/views/chatGame/index.vue'
import chat from '@/views/chatGame/chat/index.vue'
import roomList from '@/views/chatGame/gomoku/roomList.vue'
import room from '@/views/chatGame/gomoku/room.vue'
import admin from '@/views/admin/index.vue'
import dataPage from '@/views/admin/dataPage/index.vue'
import userAdmin from '@/views/admin/user/index.vue'
import articleAdmin from '@/views/admin/article/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomePage,
    meta: {
      title: '小雨开发 - 程序员一站式响应编程博客，博客官网'
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: home,
        meta: {
          title: '小雨开发 - 程序员一站式响应编程博客，博客官网'
        }
      },
      {
        path: '/article',
        name: 'article',
        component: article,
        meta: {
          title: '文章详情'
        }
      },
      {
        path: '/admin',
        name: 'admin',
        component: admin,
        meta: {
          title: '管理'
        },
        children: [
          {
            path: '/',
            name: 'dataPage',
            component: dataPage,
            meta: {
              title: '数据总览'
            }
          },
          {
            path: '/admin/userAdmin',
            name: 'userAdmin',
            component: userAdmin,
            meta: {
              title: '用户管理'
            }
          },
          {
            path: '/admin/articleAdmin',
            name: 'articleAdmin',
            component: articleAdmin,
            meta: {
              title: '文章管理'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/editor',
    name: 'editor',
    component: editor,
    meta: {
      title: '发文章'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: {
      title: '关于'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录/注册'
    }
  },
  {
    path: '/blank',
    name: 'blank',
    component: blank,
    meta: {
      title: '警告'
    }
  },
  {
    path: '/ios',
    name: 'ios',
    component: ios,
    meta: {
      title: '获取UDID'
    }
  },
  {
    path: '/chatGame',
    name: 'chatGame',
    component: chatGame,
    meta: {
      title: '聊天娱乐'
    },
    children: [
      { path: '', redirect: '/chatGame/chat' },
      {
        path: '/chatGame/chat',
        name: 'chat',
        component: chat,
        meta: {
          title: '聊天'
        }
      },
      {
        path: '/chatGame/roomList',
        name: 'roomList',
        component: roomList,
        meta: {
          title: '五子棋房间列表'
        }
      },
      {
        path: '/chatGame/room',
        name: 'room',
        component: room,
        meta: {
          title: '五子棋对战房间'
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  //to是当前页面,from是从哪里来,next是放行
  if (to.meta.name) {
    document.title = to.meta.title  //获取从上个页面传过来的name再进行修改
  }
  next() //切记操作完一定要记得放行,否则无法正常跳转页面
})

export default router
