<template>
  <div class='chat-game'>
    <navBox :navList="navList" background="#1e1e1e"></navBox>
    <snowflake />
    <div class="page-body">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import navBox from '@/components/nav.vue'
import snowflake from '@/components/snowflake.vue'
import io from 'socket.io-client';
export default {
  components: {
    snowflake,
    navBox
  },
  props: {

  },
  data() {
    return {
      navList: [
        { name: '首页', path: '/' },
        { name: '聊天', path: '/chatGame/chat' },
        { name: '五子棋', path: '/chatGame/roomList' },
      ],
      user: ''
    };
  },
  created() {
    this.user = this.$tool.getStorage('user')
    if (this.user.avatar.indexOf('http') == -1) {
      this.user.avatarUrl = require(`@/assets/avatar/${this.user.avatar}`)
    }
    Vue.prototype.$socket = io(process.env.VUE_APP_SOCKET_API);
  },
  destroyed() {
    this.$socket.close()
  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>
.page-body {
  padding-top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-game {
  width: 100%;
  height: 100vh;

  background: #000;
  // background-image: linear-gradient(135deg, #020f2d 0%, #000102 100%);
}
</style>