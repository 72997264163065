<template>
  <div class=''>
    <div class="item" v-if="item.type == 1">
      <img :src="item.avatarUrl" alt="">
      <div class="msg-box">
        <div class="name">{{ item.nickname || item.userName }}</div>
        <div class="msg-txt">
          {{ item.msg }}
        </div>
      </div>
    </div>
    <div class="item my" v-if="item.type == 0">
      <img :src="item.avatarUrl" alt="">
      <div class="msg-txt">
        {{ item.msg }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    item: Object
  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>
.my {
  flex-direction: row-reverse;

  .msg-txt {
    margin-left: 0 !important;
    margin-right: 10px;
    text-align: left;
    color: #fff !important;
    background: #404040 !important;

    &::after {
      left: 100% !important;
      transform: translateX(-1px) !important;
      border-left-color: #404040 !important;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
    }
  }
}

.name {
  margin-left: 10px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #fff;
}

.item {
  position: relative;
  margin-bottom: 20px;
  display: flex;

  img {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .msg-txt {
    max-width: calc(100% - 50px);
    margin-left: 10px;
    vertical-align: top;
    display: inline-block;
    word-break: break-all;
    padding: 10px;
    color: #AAAAAA;
    border-radius: 10px;
    background: #171717;
    position: relative;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);

    &::after {
      content: '';
      position: absolute;
      left: 1px;
      top: 5px;
      transform: translateX(-99%);
      width: 0;
      height: 0;
      border: 6px solid #171717;
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }
}
</style>