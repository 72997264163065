<template>
  <div class="send-btn">
    <textarea ref="inputA" @keyup.enter="sendMsg" v-model="message" name="message" placeholder="说点什么吧..." />
    <div class="right-btn">
      <div class="emoji" @click="isShowEmoji = !isShowEmoji">
        <emoji v-if="isShowEmoji" @change="changeEmoji"></emoji>
        <img src="@/assets/img/emoji.png" alt="">
      </div>
      <div :style="{ background: message ? '' : '#04446b' }" class="btn" @click="sendMsg">
        <img src="@/assets/img/send.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import emoji from '@/components/emoji/index.vue'

export default {
  components: {
    emoji
  },
  props: {

  },
  data() {
    return {
      message: '',
      isShowEmoji: false
    };
  },
  created() {

  },
  methods: {
    sendMsg() {
      this.$emit('sendMsg', this.message)
      this.message = ''
    },
    changeEmoji(val) {
      this.message += val
      this.isShowEmoji = false
      this.$refs.inputA.focus()
    },
  },
}
</script>
<style lang='scss' scoped>
* {
  box-sizing: border-box;
}

.send-btn {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right-btn {
    display: flex;
    align-items: center;
  }

  textarea {
    height: 45px;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 20px;
    border: none;
    background-color: #404040;
    color: white;
    width: 90%;
    margin-right: 10px;
    outline: none;
    resize: none;
  }

  .emoji {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    background-color: #0da4ff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: relative;

    img {
      width: 26px;
      height: 26px;
    }
  }

  .btn {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    background-color: #0da4ff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 35px;
      height: 35px;
    }
  }

  // textarea {
  //   width: 100%;
  //   font-size: 16px;
  //   background: #f5f5f5;
  //   border: none;
  //   outline: none;
  // }
}
</style>