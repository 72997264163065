<template>
  <div class="chat-box">
    <div class="left">
      <img class="photo" :src="user.avatarUrl" alt="">
      <div class="name">{{ user.nickName || user.userName }}</div>
      <div class="desc">{{ user.desc || '这个人很懒什么也没留下...' }}</div>
    </div>
    <div class="middle">
      <div class="middle-con">
        <div class="con" ref="chatContainer">
          <msgBox v-for="(item, index) in msgList" :key="index" :item="item"></msgBox>
        </div>
      </div>
      <messageInput @sendMsg="sendMsg"></messageInput>
    </div>
    <div class="right">
      <userOnline :userList="userList"></userOnline>
    </div>
  </div>
</template>

<script>
import messageInput from '@/components/messageInput.vue'
import userOnline from '@/components/userOnline.vue'
import navBox from '@/components/nav.vue'
import snowflake from '@/components/snowflake.vue'
import msgBox from '@/components/msgBox.vue'
import emoji from '@/components/emoji/index.vue'
export default {
  components: {
    snowflake,
    msgBox,
    emoji,
    navBox,
    userOnline,
    messageInput
  },
  props: {

  },
  data() {
    return {
      msgList: [],
      isShowEmoji: false,
      userList: [],
      userLineList: [],
      user: ''
    };
  },
  created() {
    this.user = this.$tool.getStorage('user')
    this.$socket.emit('userLine', this.user)
  },
  mounted() {
    this.userLine()
    this.onMessage()
    this.scrollToBottom()
    this.findUser()
  },
  methods: {
    findUser() {
      this.$http.post('/user/findUser', {}).then(res => {
        if (res.status === 1) {
          this.userList = res.result.filter(v => v._id !== this.user._id)
          this.userList.forEach(item => {
            if (item.avatar.indexOf('http') == -1) {
              item.avatarUrl = require(`@/assets/avatar/${item.avatar}`)
            }
          })
          this.userLineList.forEach(item => {
            this.userList.forEach(i => {
              if (item._id == i._id) i.socketId = item.socketId
            })
          })
          this.userList.forEach(item => {
            item.seq = 0
            if (item.socketId) item.seq = 1
          })
          this.userList.sort((a, b) => {
            return b.seq - a.seq
          })
        }
      })
    },
    userLine() {
      this.$socket.on('userLine', userLine => {
        this.userLineList = userLine
        userLine.forEach(item => {
          this.userList.forEach(i => {
            if (item._id == i._id) i.socketId = item.socketId
          })
        })
        this.userList.forEach(item => {
          item.seq = 0
          if (item.socketId) item.seq = 1
        })
        this.userList.sort((a, b) => {
          return b.seq - a.seq
        })
      })
    },
    onMessage() {
      this.$socket.on('message', (item) => {
        item.type = 1
        this.msgList.push(item)
        this.scrollToBottom()
      })
    },

    sendMsg(message) {
      if (!message) return
      let item = JSON.parse(JSON.stringify(this.user))
      item.type = 0
      item.msg = message
      this.msgList.push(item)
      this.$socket.emit('message', item)
      this.scrollToBottom()
    },
    scrollToBottom() {
      // 使用$nextTick确保DOM更新后再滚动到底部
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        if (chatContainer) chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    }
  },
}
</script>
<style lang='scss' scoped>
.back-button {
  position: absolute;
  left: 20px;
  top: 20px;
  text-decoration: none;
  color: #fff;
  background-color: #333;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(151 151 151);
  transition: transform 0.2s ease-in-out;
  user-select: none;
  cursor: pointer;
}

.back-button:hover {
  transform: translateY(-2px);
  background-color: #555;
}

.left {
  padding: 0 10px;
  width: 150px;
  height: 100%;

  .desc {
    font-size: 14px;
    color: #8f8f8f;
  }

  .name {
    text-align: center;
    margin-bottom: 10px;
    color: #fff;
  }

  .photo {
    display: block;
    margin: 40px auto 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}

.middle {
  width: calc(100% - 300px);
  margin: 10px;
  background: #3b3b3b9c;
  border-radius: 10px;

  .middle-con {
    padding: 10px;
    height: calc(100% - 50px);
  }

  .con {
    height: 100%;
    overflow-y: auto;
  }
}

.right {
  width: 150px;
  height: 100%;
}

.chat-box {
  width: 100%;
  max-width: 800px;
  height: 600px;
  display: flex;
  background: #1e1e1e;
  border-radius: 12px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}
</style>