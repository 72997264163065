<template>
  <div>
    <div class="data-box">
      <router-view />
      <div class="home-con">
        <div class="left box-shadow">
          <tip></tip>
          <div @click="goArticle" v-for="item in 6" :key="item">
            <coolCard :border="item !== 6" :src="require('@/assets/img/abc.png')"></coolCard>
          </div>
        </div>
        <div class="right">
          <div class="home-box box-shadow">
            <img class="bg10-2" src="@/assets/bg10-2.png" alt="">
            <div class="home-text">
              <span>码农日志</span>
              <img src="@/assets/badge.png" alt="">
            </div>
            <div class="text">我坚信，它是最好用的博客平台</div>
            <div class="box">
              <div class="box-item margin blue" @click="goPage">
                <span class="yellow fa fa-hand-o-right"></span>
                <span class="box-text">聊天娱乐</span>
              </div>
              <div class="round"></div>
              <div class="box-item green">
                <span class="yellow fa fa-hand-o-left"></span>
                <span class="box-text">朋友圈</span>
              </div>
            </div>
          </div>
          <div class="Hot-article box-shadow">
            <tip></tip>
            <div class="hot-title">
              <span class="fa fa-th-large"></span>
              <span class="">热门文章</span>
            </div>
            <div class="article hove">
              <span class="fa fa-chevron-right"></span>
              <span>Java 编程练习500题</span>
            </div>
            <div class="article hove">
              <span class="fa fa-chevron-right"></span>
              <span>Java 编程练习500题</span>
            </div>
          </div>
          <div class="Hot-article Label-field box-shadow">
            <tip></tip>
            <div class="hot-title">
              <span class="fa fa-tags"></span>
              <span class="">标签栏</span>
            </div>
            <div class="tags">
              <div class="tag">Java</div>
              <div class="tag">Vue</div>
              <div class="tag">MySQL</div>
              <div class="tag">MongoDB</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      user: ''
    };
  },
  created() {
    this.user = this.$tool.getStorage('user') || ''
  },
  methods: {
    goPage() {
      if (!this.user) return this.$message.error('请先登录')
      this.$router.push(`/chatGame`)
    },
    goArticle() {
      let routeData = this.$router.resolve({ path: '/article' });
      window.open(routeData.href, '_blank');
    }
  },
}
</script>
<style lang='scss' scoped>
.Label-field {
  .tags {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    font-size: 14px;

    .tag:hover {
      color: #539dfd;
      border-color: #539dfd;
    }

    .tag {
      padding: 3px 6px;
      margin: 0 10px 10px 0;
      color: #5a5a5a;
      border: 1px solid #f3f4f9;
      cursor: pointer;
    }
  }
}

.Hot-article {
  position: relative;
  margin-top: 20px;
  background: #fff;

  .article {
    font-size: 14px;
    color: #646464;
    padding: 0 0 15px 20px;
    cursor: pointer;
    transition: all .2s;

    &:hover {
      margin-left: 10px;
    }

    .fa {
      margin-right: 3px;
      font-size: 12px;
    }
  }

  .hot-title {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    color: #727272;
    font-size: 18px;

    .fa {
      margin: 3px 4px 0;
    }
  }
}

.home-con {
  width: 100%;
  display: flex;
}

.right {
  width: 280px;
}

.left {
  position: relative;
  width: calc(100% - 300px);
  padding: 20px;
  margin-right: 20px;
  border-radius: 3px;
}

.home-box {
  padding-bottom: 20px;
  background: #fff;
}

.box {
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-top: 10px;
  position: relative;

  .round {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 1;
  }

  .margin {
    margin-right: 10px;
  }

  .blue {
    background: #539dfd;
  }

  .blue:hover {
    background: rgb(129, 170, 250);
  }

  .green:hover {
    background: rgb(129, 198, 148);
  }

  .green {
    background: #5fb878;
  }

  .fa {
    margin-right: 4px;
  }

  .box-item {
    width: calc(50% - 5px);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;

    .yellow {
      color: #fdd641;
    }

    .box-text {
      font-weight: 600;
      font-size: 14px;
      color: #fff;
    }
  }
}

.text {
  color: #5a5a5a;
  text-align: center;
  font-size: 14px;
}

.home-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 17px;
    font-weight: 600;
    color: #333333;
  }

  img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
}

.home-logo {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;

  img {
    width: 70px;
    height: 70px;
  }
}

.bg10-2 {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1100px) {
  .right {
    display: none;
  }

  .left {
    width: 100%;
    margin-right: 0;
  }
}
</style>